import article1 from '../../assets/images/news/1.webp'
import article2 from '../../assets/images/news/2.webp'
import article3 from '../../assets/images/news/3.webp'
import article4 from '../../assets/images/news/4.webp'
import article5 from '../../assets/images/news/5.webp'
import article6 from '../../assets/images/news/6.webp'
import article7 from '../../assets/images/news/7.webp'
import article8 from '../../assets/images/news/8.webp'
import article9 from '../../assets/images/news/9.webp'

import article10 from '../../assets/images/news/10.webp'
import article11 from '../../assets/images/news/11.webp'
import article12 from '../../assets/images/news/12.webp'
import article13 from '../../assets/images/news/13.webp'
import article14 from '../../assets/images/news/14.webp'
import article15 from '../../assets/images/news/15.webp'
import article16 from '../../assets/images/news/16.webp'
import article17 from '../../assets/images/news/17.webp'
import article18 from '../../assets/images/news/18.webp'
import article19 from '../../assets/images/news/19.webp'
import article20 from '../../assets/images/news/20.webp'
import article21 from '../../assets/images/news/21.webp'
import article22 from '../../assets/images/news/22.webp'
import article23 from '../../assets/images/news/23.webp'
import article24 from '../../assets/images/news/24.webp'
import article25 from '../../assets/images/news/25.webp'
import article26 from '../../assets/images/news/26.webp'
import { formNameToUrl } from '../../components/supportingElements/formNameToUrl'

const articlesData = [
   {
      name: 'Представители «ЭКМ Трейд» примут участие в Петербургском международном газовом форуме - 2024',
      cover: article26,
      date: '16.09.2024',
      content: `
        <p>
            Петербургский международный газовый форум пройдет с 8 по 11 октября в конгрессно-выставочном центре «ЭКСПОФОРУМ», по 
            адресу г. Санкт-Петербург, поселок Шушары, Петербургское ш., д. 64/1, лит. А.
        </p>
        <p>
            На официальном сайте Форума можно ознакомиться с деловой программой форума: 
            <a href="https://gas-forum.ru/ru/arhitektura-pmgf" target="_blank">https://gas-forum.ru/ru/arhitektura-pmgf</a> 
            и пройти бесплатную регистрацию: 
            <a href="https://gas-forum.ru/ru/registracija-posetitelej" target="_blank">https://gas-forum.ru/ru/registracija-posetitelej</a>.
        </p>
        `,
      link: formNameToUrl('Представители «ЭКМ Трейд» примут участие в Петербургском международном газовом форуме-2024'),
   },
   {
      name: 'С Днем Химика!',
      cover: article25,
      date: '26.05.2024',
      content: `
         <p>
            Уважаемые работники и ветераны химической промышленности! Примите искренние поздравления с вашим профессиональным праздником – Днем химика!
         </p>
         <p>
            Желаем вам крепкого здоровья, семейного благополучия, дальнейших производственных успехов, оптимизма и процветания!
         </p>
        `,
      link: formNameToUrl('С Днем Химика!-2024.'),
   },
   {
      name: 'Специалисты «ЭКМ Трейд» приняли участие в выставке CABEX-2024.',
      cover: article24,
      date: '07.03.2024',
      content: `
         <p>
            С 19 по 21 марта в Москве состоялась центральная выставка, посвященная кабельно-проводниковой продукции – от разработки до конечного потребителя CABEX-2024.
         </p>
         <p>
            В этом году в выставке приняли участие более 250 ведущих производителей кабельно-проводниковой продукции, оборудования и материалов России, Республики Беларусь, Китайской Народной Республики.
         </p>
        `,
      link: formNameToUrl('Специалисты «ЭКМ Трейд» приняли участие в выставке CABEX-2024.'),
   },
   {
      name: 'Поздравляем с Международным женским днем!',
      cover: article23,
      date: '07.03.2024',
      content: `
         <p>
            Этот замечательный весенний праздник – ещё одна возможность сказать самые тёплые и искренние слова представительницам прекрасной половины человечества.
         </p>
         <p>
            Женское очарование, нежность, внимание, забота, любовь и теплота одинаково нужны взрослым и детям. Своей отзывчивостью и уникальной способностью вдохновлять вы помогаете близким покорять новые вершины, воплощать идеи в жизнь.
         </p>
         <p>
            Пусть наступившая весна наполнит вас жизненной энергией, подарит яркие эмоции и теплые моменты счастья.
         </p>
         <p>
            Пусть ваши глаза сияют, исполняются желания и мечты.
         </p>
         <p>
            Весна Вам к лицу!
         </p>
        `,
      link: formNameToUrl('Поздравляем с Международным женским днем! 2024'),
   },
   {
      name: 'С Днём защитника Отечества!',
      cover: article22,
      date: '22.02.2024',
      content: `
         <p>
            Уважаемые коллеги и партнеры!
         </p>
         <p>
            В День защитника Отечества примите искренние пожелания крепкого здоровья, счастья и побед!
         </p>
         <p>
            Пусть во всех делах с Вами остаются отвага и оптимизм, сила духа и твердость характера, а в Вашем доме всегда царят благополучие и любовь!
         </p>
         <p>
            С праздником!
         </p>
         <p>
            С Днём защитника Отечества!
         </p>
        `,
      link: formNameToUrl('С Днём защитника Отечества! 2024'),
   },
   {
      name: 'С наступающим Новым годом!',
      cover: article21,
      date: '29.12.2023',
      content: `
         <p>
            Уважаемые партнеры! 
         </p>
         <p>
            Поздравляем вас с Наступающим Новым годом!
         </p>
         <p>
            Пусть в ваших глазах горит огонь, в сердце живет любовь, а в делах сопутствует удача! Искренне желаем вам процветания, упорства и неиссякаемой энергии!
         </p>
        `,
      link: formNameToUrl('С наступающим Новым годом!'),
   },
   {
      name: 'Поздравляем с Днем энергетика!',
      cover: article20,
      date: '22.11.2023',
      content: `
         <p>
            Уважаемые партнеры!
         </p>
         <p>
            Поздравляем Вас с Днем энергетика!
         </p>
        `,
      link: formNameToUrl('Поздравляем с Днем энергетика!'),
   },

   {
      name: 'Поздравляем с Днем проектировщика!',
      cover: article19,
      date: '16.11.2023',
      content: `
        <p>
            Уважаемые партнеры, поздравляем с профессиональным праздником. Желаем интересных задач, творческих идей и креативных проектов.
        </p>
        <p>
            Без вас идея останется лишь мечтой. Спасибо, что помогаете воплощать в жизнь даже самые сложные задачи.
        </p>
        <p>
            С праздником!
        </p>
        `,
      link: formNameToUrl('Поздравляем с Днем проектировщика!'),
   },
   {
      name: 'Специалисты «ЭКМ Трейд» приняли участие в Петербургском международном газовом форуме.',
      cover: article18,
      date: '07.11.2023',
      content: `
        С 31 октября по 3 ноября в Экспофоруме прошло самое значимое и ожидаемое событие газовой отрасли – Петербургский международный газовый форум. 
        Участие в выставочной программе ПМГФ-2023 приняли более 500 компаний.
        `,
      link: formNameToUrl('Специалисты «ЭКМ Трейд» приняли участие в Петербургском международном газовом форуме'),
   },
   {
      name: 'С Днем работника атомной промышленности!',
      cover: article17,
      date: '28.09.2023',
      content: `
        <p>
            Уважаемые партнеры!
        </p>
        <p>
            Поздравляем с профессиональным праздником - Днем работников атомной промышленности!
        </p>
        <p>
            Мы гордимся сотрудничеством с вами в этой важной сфере, где наши усилия способствуют безопасной и устойчивой энергии.
        </p>
        <p>
            Вместе мы создаем будущее, освещенное инновациями и прогрессом.
        </p>
        <p>
            Пусть наше партнерство продолжает расти и процветать!
        </p>
        `,
      link: formNameToUrl('С Днем работника атомной промышленности!'),
   },
   {
      name: '«ЭКМ Трейд» принял участие в 17-й Международной выставке по гражданскому судостроению «НЕВА 2023»',
      cover: article16,
      date: '22.09.23',
      content: `
         <p>
            Специалисты ООО «ЭКМ Трейд»  приняли  участие в международной выставке «НЕВА», которая состоялась с 18 по 21 сентября 2023 года в Санкт-Петербурге, КВЦ Экспофорум. 
         </p>
         <p>
             «НЕВА» – крупнейшая гражданская морская выставка в Восточной Европе и на всём постсоветском пространстве, которая входит в пятерку крупнейших мировых выставок этой отрасли.
         </p>
        `,
      link: formNameToUrl('«ЭКМ Трейд» принял участие в 17-й Международной выставке по гражданскому судостроению «НЕВА 2023»'),
   },
   {
      name: 'Уважаемые коллеги и партнеры! Поздравляем с Днем работников нефтяной и газовой промышленности!',
      cover: article15,
      date: '03.09.2023',
      content: `Желаем успехов и процветания Вашему делу!`,
      link: formNameToUrl('Уважаемые коллеги и партнеры! Поздравляем с Днем работников нефтяной и газовой промышленности!'),
   },
   {
      name: 'Представители ООО «ЭКМ Трейд» посетили международный военно-технический форум «Армия-2023».',
      cover: article14,
      date: '21.08.23',
      content: `Представители ООО «ЭКМ Трейд» посетили международный военно-технический форум «Армия-2023», который проходил с 14 по 20 августа 2023 года  на территории Конгрессно-выставочного 
                  центра «Патриот», на полигоне «Алабино», аэродроме «Кубинка» (Московская область).`,
      link: formNameToUrl('Представители ООО «ЭКМ Трейд» посетили международный военно-технический форум «Армия-2023»'),
   },
   {
      name: 'Уважаемые партнеры! Поздравляем с Днем металлурга!',
      cover: article13,
      date: '16.07.2023',
      content: ``,
      link: formNameToUrl('Уважаемые партнеры! Поздравляем с Днем металлурга!'),
   },
   {
      name: 'С Днём работников морского и речного флота!',
      cover: article12,
      date: '02.07.2023',
      content: ``,
      link: formNameToUrl('С Днём работников морского и речного флота!'),
   },
   {
      name: 'Уважаемые партнеры! С Днем кораблестроителя!',
      cover: article11,
      date: '29.06.2023',
      content: ``,
      link: formNameToUrl('Уважаемые партнеры! С Днем кораблестроителя!'),
   },
   {
      name: 'С Днем России!',
      cover: article10,
      date: '12.06.2023',
      content: ``,
      link: formNameToUrl('С Днем России!'),
   },
   {
      name: 'Уважаемые партнеры! Поздравляем вас с днём химика!',
      cover: article9,
      date: '28.05.2023',
      content: ``,
      link: formNameToUrl('Уважаемые партнеры! Поздравляем вас с днём химика!'),
   },
   {
      name: 'Уважаемые партнеры! Поздравляем со Всемирным днём метрологии!',
      cover: article8,
      date: '19.05.2023',
      content: ``,
      link: formNameToUrl('Уважаемые партнеры! Поздравляем со Всемирным днём метрологии!'),
   },
   {
      name: 'С Днем Победы!',
      cover: article7,
      date: '05.05.2023',
      content: `
         <p>
            Уважаемые коллеги и партнеры! Поздравляем вас с Днем Победы! 
         </p>
         <p>
            Мира, счастья, добра, здоровья и благополучия!
         </p>
      `,
      link: formNameToUrl('С Днем Победы!'),
   },
   {
      name: 'С 24 по 27 апреля сотрудники «ЭКМ Трейд» посетили стенд своего дистрибьютера ООО «ЭКМ Холдинг» на выставке «Нефтегаз 2023».',
      cover: article6,
      date: '05.05.2023',
      content: `
         <p>
            Все желающие могли ознакомиться на стенде с продукцией компании для нефтегазовой отрасли – силовой и монтажный кабели торговой марки «ИнСил», монтажный кабель 
            торговой марки «КуПе», а также кабеленесущие системы «СКИНЕР» и система электрообогрева «КСТерм». 
         </p>
         <p>
            В ходе выставки компания представила новый кабель – судовой МКПс.  Отличительная особенность этого кабеля – температурный диапазон эксплуатации – от -70°С до +110°С, 
            повышенный срок службы до 60 лет, отвечающий требованиям IEC 60092-360/SHF2. Данный вид кабеля обладает характеристиками повышенной устойчивости к механическому воздействию, 
            UV-лучам, соляному туману, морской воде и агрессивным средам и активно применяется на объектах береговой инфраструктуры морских портов РФ.
         </p>
      `,
      link: formNameToUrl(
         'С 24 по 27 апреля сотрудники «ЭКМ Трейд» посетили стенд своего дистрибьютера ООО «ЭКМ Холдинг» на выставке «Нефтегаз 2023».'
      ),
   },
   {
      name: 'С праздником весны и труда!',
      cover: article5,
      date: '28.04.2023',
      content: '',
      link: formNameToUrl('С праздником весны и труда!'),
   },
   {
      name: 'С наступающим праздником Ураза Байрам!',
      cover: article4,
      date: '21.04.2023',
      content:
         'От всей души желаем чистых мыслей, доброго здоровья, богатого стола, дружной семьи, счастливого сердца, взаимной любви, достойной жизни.',
      link: formNameToUrl('С наступающим праздником Ураза Байрам!'),
   },
   {
      name: 'Поздравляем со светлым праздником Пасхи!',
      cover: article3,
      date: '16.04.2023',
      content: 'Желаем Вам и Вашим родным мира, любви, процветания и здоровья!',
      link: formNameToUrl('Поздравляем со светлым праздником Пасхи!'),
   },
   {
      name: 'Прекрасные, милые дамы! От всей души поздравляем Вас с наступающим праздником!',
      cover: article1,
      date: '07.03.2023',
      content: 'Желаем вам светлых и ярких моментов в жизни, которые согреют ваше сердце!',
      link: formNameToUrl('Прекрасные, милые дамы! От всей души поздравляем Вас с наступающим праздником!'),
   },
   {
      name: 'С Днем защитника Отечества!',
      cover: article2,
      date: '22.02.2023',
      content: '',
      link: formNameToUrl('С Днем защитника Отечества!'),
   },
]

export { articlesData }
